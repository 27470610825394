import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { myApi } from "../helpers/api";
const AuthContext = createContext();

const useAuth = () => {
  return useContext(AuthContext);
};



const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
 


  return (
    <AuthContext.Provider
      value={{ user, setUser, isLoggedIn, setIsLoggedIn}}
    >
      {children}
    </AuthContext.Provider>
  );

  
};

export { AuthProvider, useAuth };

